import { resolveNextAccordion } from '@utils/accordion'
import { getKeyLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { AccordionEnum, IVisitType } from '../store/data'
import { useAccordion } from './useAccordion'
import { HOTEL_PARAM, ROOM_TYPE_PARAM, useQueryParams, VISIT_TYPE_PARAM } from './useQueryParams'

export const useVisitTypeCategoriesAccordion = () => {
  const accordion = useBookingFormStore((state) => state.accordion)
  const language = useBookingFormStore((state) => state.language)
  const hotel = useBookingFormStore((state) => state.hotel)
  const destination = useBookingFormStore((state) => state.destination)
  const guest = useBookingFormStore((state) => state.guest)
  const roomType = useBookingFormStore((state) => state.roomType)
  const visitType = useBookingFormStore((state) => state.visitType)
  const changeVisitType = useBookingFormStore((state) => state.changeVisitType)
  const changeHotel = useBookingFormStore((state) => state.changeHotel)
  const changeRoomType = useBookingFormStore((state) => state.changeRoomType)

  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()

  const { onChangeAccordion } = useAccordion()

  const removeRoomHotel = () => {
    changeHotel(null)
    changeRoomType(null)
    removeQueryParametersArray([HOTEL_PARAM, ROOM_TYPE_PARAM])
  }

  const updateRouteQuery = async (value: string | null | undefined) => {
    if (value) {
      await addQueryParameter(VISIT_TYPE_PARAM, value)
    } else {
      await removeQueryParameter(VISIT_TYPE_PARAM)
    }
  }

  const validateVisitType = (value: IVisitType) => {
    // check if hotel is allowed in the visit type, if not remove the hotel and room selection
    if (hotel && !value.hotels.some((item) => item.id === hotel.id)) {
      removeRoomHotel()
    }
  }

  const onChangeVisitType = async (value: IVisitType | null) => {
    if (value) {
      validateVisitType(value)
      const nextAccordion = resolveNextAccordion(accordion as AccordionEnum, {
        destination: !!destination,
        guest: !!guest,
        hotel: !!hotel,
        roomType: !!roomType,
        visitType: !!visitType,
      })
      onChangeAccordion(nextAccordion)
    }

    changeVisitType(value)
    const visitTypeName = value?.name[getKeyLang(language)]
    await updateRouteQuery(visitTypeName)
  }

  const onInitVisitTypeValidation = (value: IVisitType | null) => {
    if (value) {
      validateVisitType(value)
    } else {
      removeQueryParameter(VISIT_TYPE_PARAM)
    }
  }

  return {
    onChangeVisitType,
    onInitVisitTypeValidation,
    removeRoomHotel,
  }
}
