import * as React from 'react'

const GuestsIcon: React.FunctionComponent = () => (
  <svg fill="none" height="28" viewBox="0 0 46 28" width="46" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 0.5C7.547 0.5 3.5 4.547 3.5 9.5C3.50182 10.9173 3.83835 12.3141 4.48218 13.5768C5.12601 14.8394 6.05894 15.9322 7.205 16.766C3.245 18.731 0.5 22.796 0.5 27.5H3.5C3.5 22.511 7.511 18.5 12.5 18.5C17.489 18.5 21.5 22.511 21.5 27.5H24.5C24.5 22.511 28.511 18.5 33.5 18.5C38.489 18.5 42.5 22.511 42.5 27.5H45.5C45.5 22.796 42.755 18.731 38.795 16.766C39.9412 15.9323 40.8743 14.8396 41.5181 13.577C42.162 12.3143 42.4984 10.9174 42.5 9.5C42.5 4.547 38.453 0.5 33.5 0.5C28.547 0.5 24.5 4.547 24.5 9.5C24.5018 10.9173 24.8383 12.3141 25.4822 13.5768C26.126 14.8394 27.0589 15.9322 28.205 16.766C26.0122 17.8417 24.1973 19.5572 23 21.686C21.8027 19.5572 19.9878 17.8417 17.795 16.766C18.9412 15.9323 19.8743 14.8396 20.5181 13.577C21.162 12.3143 21.4984 10.9174 21.5 9.5C21.5 4.547 17.453 0.5 12.5 0.5ZM12.5 3.5C15.83 3.5 18.5 6.17 18.5 9.5C18.5 12.83 15.83 15.5 12.5 15.5C9.17 15.5 6.5 12.83 6.5 9.5C6.5 6.17 9.17 3.5 12.5 3.5ZM33.5 3.5C36.83 3.5 39.5 6.17 39.5 9.5C39.5 12.83 36.83 15.5 33.5 15.5C30.17 15.5 27.5 12.83 27.5 9.5C27.5 6.17 30.17 3.5 33.5 3.5Z"
      fill="currentColor"
    />
  </svg>
)

export default GuestsIcon
