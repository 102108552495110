import { format } from 'date-fns'
import * as React from 'react'
import { DATE_FORMAT_YYYY_MM_DD } from '../../constants'
import { PathEnum, redirectTo, reportConversion } from '../../helpers'
import { useIsAllBookingParams } from '../../hooks/useIsAllBookingParams'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import {
  GuestsFormData,
  IDestination,
  IHotel,
  IPrice,
  IRoomType,
  ITravelDate,
  IVisitType,
  LoyaltyProgramData,
  PersonalFormData,
} from '../../store/data'

export type BookingRequest = (binding: boolean) => void

export type AllBookingParams = {
  destination: IDestination | null
  guest: number | null
  guestsData: GuestsFormData
  hotel: IHotel | null
  isAllBookingParams: boolean
  lowestPrice: IPrice | null
  onBookingRequest: BookingRequest
  onlyAvailableDemand: boolean
  personalData: PersonalFormData | null
  price: IPrice | null
  roomType: IRoomType | null
  travelDate: ITravelDate | null
  visitType: IVisitType | null
}

const getLoyaltyProgramsForDependencyArray = (loyaltyPrograms: LoyaltyProgramData[]) => {
  const programIds = loyaltyPrograms.map((program) => program.id)
  return programIds.join('-')
}

export const useAllBookingParams = (): AllBookingParams => {
  const currency = useBookingFormStore((state) => state.currency)
  const language = useBookingFormStore((state) => state.language)
  const onlyAvailableDemand = useBookingFormStore((state) => state.onlyDemand)
  const setDemand = useBookingFormStore((state) => state.setDemand)
  const setRouteQueryParams = useBookingFormStore((state) => state.setRouteQueryParams)
  const lowestPrice = useBookingFormStore((state) => state.lowestPrice)
  const serviceDatas = useBookingFormStore((state) => state.servicesData)
  const guestsData = useBookingFormStore((state) => state.guestsData)
  const personalData = useBookingFormStore((state) => state.personalData)
  const destination = useBookingFormStore((state) => state.destination)
  const guest = useBookingFormStore((state) => state.guest)
  const price = useBookingFormStore((state) => state.price)
  const hotel = useBookingFormStore((state) => state.hotel)
  const roomType = useBookingFormStore((state) => state.roomType)
  const visitType = useBookingFormStore((state) => state.visitType)
  const travelDate = useBookingFormStore((state) => state.travelDate)
  const loadPrice = useBookingFormStore((state) => state.loadPrice)
  const loadLowestPrice = useBookingFormStore((state) => state.loadLowestPrice)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const appInitializedAndValidated = useBookingFormStore(
    (state) => state.appInitializedAndValidated
  )

  const { routerQueryParams } = useQueryParams()
  const isAllBookingParams = useIsAllBookingParams()

  // The useEffect dependency array uses shallow comparison. Simply using serviceData.loyaltyPrograms as an array is insufficient; therefore, we need to stringify it.
  const loyaltyProgramsString = getLoyaltyProgramsForDependencyArray(serviceDatas.loyaltyPrograms)

  const onBookingRequest = (demand: boolean) => {
    if (onlyAvailableDemand) {
      setDemand(true)
    } else {
      setDemand(demand)
    }
    reportConversion('beginCheckout', language, price, currency, visitType, roomType, hotel)
    setRouteQueryParams(routerQueryParams)
    redirectTo(PathEnum.STEP_2, {}, language)
  }

  React.useEffect(() => {
    const fetchPrice = async (query: URLSearchParams) => {
      await loadPrice(query)
    }

    const fetchLowestPrice = async (query: URLSearchParams) => {
      await loadLowestPrice(query)
    }

    if (appInitialized && appInitializedAndValidated) {
      const query = new URLSearchParams()

      if (guest && !roomType?.id) {
        query.append('number_of_beds', guest.toString())
      }
      if (visitType?.id) {
        query.append('visit_type_id', visitType.id.toString())
      }
      if (hotel?.id) {
        query.append('hotel_id', hotel.id.toString())
      }
      if (roomType?.id) {
        query.append('room_type_id', roomType.id.toString())
      }

      if (isAllBookingParams) {
        if (travelDate.from && travelDate.to) {
          query.append('date_from', format(travelDate.from, DATE_FORMAT_YYYY_MM_DD))
          query.append('date_to', format(travelDate.to, DATE_FORMAT_YYYY_MM_DD))
        }
        query.delete('hotel_id')
        query.delete('number_of_beds')

        for (let i = 0; guest && i < guest; i++) {
          const loyaltyProgramId = serviceDatas.loyaltyPrograms?.[i]?.id.toString() || false
          if (loyaltyProgramId) {
            query.append(`guest_${i + 1}_loyalty_program_id`, loyaltyProgramId)
          }
        }

        fetchPrice(query)
      } else {
        if (destination?.id) {
          query.append('destination_id', destination.id.toString())
        }

        fetchLowestPrice(query)
      }
    }
  }, [
    guest,
    hotel,
    travelDate?.from,
    travelDate?.to,
    roomType?.id,
    visitType?.id,
    isAllBookingParams,
    loadLowestPrice,
    loadPrice,
    loyaltyProgramsString,
    destination?.id,
    appInitialized,
    appInitializedAndValidated,
  ])

  return {
    destination,
    guest,
    guestsData,
    hotel,
    isAllBookingParams,
    lowestPrice,
    onBookingRequest,
    onlyAvailableDemand,
    personalData,
    price,
    roomType,
    travelDate,
    visitType,
  }
}
