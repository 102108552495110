import * as React from 'react'

type StarIconProps = {
  size?: number
}

const InfoIcon: React.FunctionComponent<StarIconProps> = ({ size = 24 }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect height="22" rx="11" stroke="currentColor" strokeWidth="2" width="22" x="1" y="1" />
    <path
      d="M12 16V12"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M12 8H12.01"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default InfoIcon
