import { useCallback } from 'react'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { AccordionEnum } from '../store/data'
import { useQueryParams } from './useQueryParams'

export const useAccordion = () => {
  const { routerQueryParams } = useQueryParams()
  const changeAccordion = useBookingFormStore((state) => state.changeAccordion)

  const onChangeAccordion = useCallback((value: AccordionEnum | null) => {
    changeAccordion(value)
  }, [])

  const resolveAccordionFromRouteQuery = () => {
    const lastFilledAccordion = [
      routerQueryParams.destination,
      routerQueryParams.guest,
      routerQueryParams.hotel,
      routerQueryParams.visitType,
      routerQueryParams.roomType,
      routerQueryParams.visitStart && routerQueryParams.visitEnd,
    ].findIndex((value) => !value)

    const accordionFromRouteQuery = lastFilledAccordion === -1 ? null : lastFilledAccordion

    return accordionFromRouteQuery
  }

  return {
    onChangeAccordion,
    resolveAccordionFromRouteQuery,
  }
}
