import * as React from 'react'
import {
  getVisitTypesFilter,
  getVisitTypesForHotelFilter,
} from '@components/VisitTypeCategoriesAccordion/helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { Identificator, IVisitCategory, IVisitType } from '../../store/data'
import VisitTypeCategory from './VisitTypeCategory'
import VisitTypeCategorySkeleton from './VisitTypeCategorySkeleton'

type VisitTypesCategoriesProps = {
  hotelId: Identificator | null
  onLoadVisitType: (type: IVisitType) => void
  onSelectVisitType: (visitType: IVisitType) => Promise<void>
  onSelectVisitTypeCategory: (visitType: IVisitCategory) => void
  selectVisitType?: IVisitType | null
  selectVisitTypeCategory?: IVisitCategory | null
  visitCategories: IVisitCategory[]
}

const VisitTypesCategories: React.FunctionComponent<VisitTypesCategoriesProps> = ({
  hotelId,
  onLoadVisitType,
  onSelectVisitType,
  onSelectVisitTypeCategory,
  selectVisitType = null,
  selectVisitTypeCategory = null,
  visitCategories,
}) => {
  const language = useBookingFormStore((state) => state.language)
  const isFetchingVisitCategories = useBookingFormStore((state) => state.isFetchingVisitCategories)

  const data = visitCategories
    .filter((visitCategories) => visitCategories.visitTypes.length > 0)
    .filter(
      (visitCategories) =>
        getVisitTypesForHotelFilter(visitCategories.visitTypes, hotelId).length > 0
    )
    .filter(
      (visitCategories) => getVisitTypesFilter(visitCategories.visitTypes, language).length > 0
    )
  return (
    <>
      {data.map((visitCategory) => (
        <VisitTypeCategory
          key={visitCategory.id}
          onLoadVisitType={onLoadVisitType}
          onSelectVisitType={onSelectVisitType}
          onSelectVisitTypeCategory={onSelectVisitTypeCategory}
          selectVisitType={selectVisitType}
          selectVisitTypeCategory={selectVisitTypeCategory}
          visitCategory={visitCategory}
        />
      ))}
      {isFetchingVisitCategories &&
        Array.from(Array(4).keys()).map((item) => <VisitTypeCategorySkeleton key={item} />)}
    </>
  )
}

export default VisitTypesCategories
