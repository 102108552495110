import { addDays, endOfMonth, format, startOfMonth } from 'date-fns'
import * as React from 'react'
import { useMemo } from 'react'
import { DATE_FORMAT_YYYY_MM_DD } from '../../constants'
import { useCalendarCache } from '../../hooks/useCalendarCache'
import { CalendarModeEnum, IDateCheckout, ITravelDate } from '../../store/data'
import CalendarWrapper from './CalendarWrapper'
import { useLocale } from './helpers'

type CalendarContentProps = {
  checkouts: IDateCheckout[]
  date: Date
  isFetching: boolean
  mode: CalendarModeEnum
  onSelectDate: (date: Date) => void
  travelDate: ITravelDate
}

const CalendarContent: React.FunctionComponent<CalendarContentProps> = ({
  checkouts = [],
  date,
  isFetching = false,
  mode,
  onSelectDate,
  travelDate,
}) => {
  const leftCalendarDate = date
  const rightCalendarDate = addDays(endOfMonth(date), 1)

  const locale = useLocale()
  const { getCachedDatesForKey } = useCalendarCache()

  const leftCalendarDatesCache = useMemo(() => {
    const dateCacheKey = format(startOfMonth(leftCalendarDate), DATE_FORMAT_YYYY_MM_DD)
    return getCachedDatesForKey(dateCacheKey)
  }, [leftCalendarDate, getCachedDatesForKey])

  const rightCalendarDatesCache = useMemo(() => {
    const dateCacheKey = format(startOfMonth(rightCalendarDate), DATE_FORMAT_YYYY_MM_DD)
    return getCachedDatesForKey(dateCacheKey)
  }, [rightCalendarDate, getCachedDatesForKey])

  return (
    <div className="calendar-content">
      <CalendarWrapper
        checkouts={checkouts}
        data={isFetching ? [] : leftCalendarDatesCache ? leftCalendarDatesCache : []}
        date={leftCalendarDate}
        isFetching={isFetching}
        locale={locale}
        mode={mode}
        onSelectDate={onSelectDate}
        travelDate={travelDate}
      />
      <CalendarWrapper
        checkouts={checkouts}
        data={isFetching ? [] : rightCalendarDatesCache ? rightCalendarDatesCache : []}
        date={rightCalendarDate}
        isFetching={isFetching}
        locale={locale}
        mode={mode}
        onSelectDate={onSelectDate}
        secondary
        travelDate={travelDate}
      />
    </div>
  )
}

export default CalendarContent
