import { addDays, format, Locale, startOfWeek } from 'date-fns'
import * as React from 'react'
import { DATE_FORMAT_YYYY_MM_DD } from '../../constants'

type CalendarWeekDaysNamesProps = {
  date: Date
  locale: Locale
}

const CalendarWeekDaysNames: React.FunctionComponent<CalendarWeekDaysNamesProps> = ({
  date,
  locale,
}) => {
  const weekStartDate = startOfWeek(date, { locale })
  const weekDays = []
  for (let day = 0; day < 7; day++) {
    const date = addDays(weekStartDate, day)
    weekDays.push(
      <div className="dayName" key={format(date, DATE_FORMAT_YYYY_MM_DD)}>
        {format(date, 'EEEEE', { locale })}
      </div>
    )
  }
  return <div className="weekContainer">{weekDays}</div>
}

export default CalendarWeekDaysNames
