import { getPriceOrNull } from '../../helpers'
import { Identificator, ILanguage, IVisitType } from '../../store/data'

export const getVisitTypesFilter = (data: IVisitType[], language: ILanguage): IVisitType[] =>
  data.filter((item) => getPriceOrNull(item.lowest_price, 'amount_per_night', language) !== null)

export const getVisitTypesForHotelFilter = (
  data: IVisitType[],
  hotelId: Identificator | null
): IVisitType[] =>
  data.filter((item) => {
    if (!hotelId) {
      return false
    }

    const { hotels: visitTypeHotels } = item

    const visitTypeHotelsIds = visitTypeHotels.map((visitTypeHotel) => Number(visitTypeHotel.id))
    return visitTypeHotelsIds.includes(Number(hotelId))
  })
