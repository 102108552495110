import * as React from 'react'

const PreviousIcon: React.FunctionComponent = () => (
  <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 13L1 7L7 1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default PreviousIcon
