import clsx from 'classnames'
import * as React from 'react'
import StarIcon from './StarIcon'

type StarsProps = {
  className?: string
  value: number
}

const Stars: React.FunctionComponent<StarsProps> = ({ className = null, value }) =>
  value > 0 ? (
    <div className={clsx('flex', className)}>
      {Array.from(Array(value).keys()).map((item) => (
        <StarIcon key={item} />
      ))}
    </div>
  ) : null

export default Stars
