import { format, Locale } from 'date-fns'
import * as React from 'react'
import Typography from '../Typography'

type CalendarHeaderProps = {
  date: Date
  locale: Locale
}

const CalendarHeader: React.FunctionComponent<CalendarHeaderProps> = ({ date, locale }) => (
  <div className="flex justify-center">
    <Typography className="font-medium" component="h2" variant="subcategory">
      {format(date, 'LLLL', { locale })}
    </Typography>
  </div>
)

export default CalendarHeader
