import * as React from 'react'
import { getLang } from '../../helpers'
import { ILanguage, ITag } from '../../store/data'
import Tag from '../Tag'

type TagsProps = {
  language: ILanguage
  select: boolean
  tags: ITag[]
}

const Tags: React.FunctionComponent<TagsProps> = ({ language, select = false, tags }) => (
  <div>
    {tags.map((tag) => (
      <Tag key={tag.id} variant={select ? 'primary' : 'default'}>
        {getLang(tag.name, language)}
      </Tag>
    ))}
  </div>
)

export default Tags
