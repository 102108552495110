import clsx from 'classnames'
import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import Image from './Image'
import styles from './Markdown.module.scss'

type MarkdownProps = {
  seeMore?: boolean
  source: string
}

const Markdown: React.FunctionComponent<MarkdownProps> = ({ seeMore = false, source }) => {
  return (
    <>
      <ReactMarkdown
        className={clsx(styles.root, seeMore && styles.rootMinimizeOverviewContent)}
        components={{
          img({ alt, height, src, width }) {
            return <Image alt={alt} height={height} src={src} width={width} />
          },
        }}
      >
        {source}
      </ReactMarkdown>
      {seeMore && <div className={clsx(styles.rootMinimizeOverviewGradient)} />}
    </>
  )
}

export default Markdown
