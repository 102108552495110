import * as React from 'react'
import Skeleton from '../Skeleton'
import UpIcon from '../UpIcon'

const VisitTypeCategorySkeleton = () => (
  <div className="w-full my-2 md:my-4 border-t-2 border-card-default pt-2">
    <div className="select-none flex items-center justify-between">
      <div className="p-2 outline-0 text-left flex items-center justify-between flex-1">
        <div className="flex flex-col justify-center text-left">
          <h3 className="inline text-category">
            <Skeleton width={220} />
          </h3>
          <span className="text-caption text-muted uppercase">
            <Skeleton height={16} width={50} />
          </span>
        </div>
        <UpIcon />
      </div>
    </div>
  </div>
)

export default VisitTypeCategorySkeleton
