import * as React from 'react'
import Skeleton from '@components/Skeleton'
import styles from './AccordionTitle.module.scss'
import Typography from './Typography'

type AccordionTitleProps = {
  isLoading?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
}

const AccordionTitle: React.FC<React.PropsWithChildren<AccordionTitleProps>> = ({
  children,
  isLoading = false,
  prefix = null,
  suffix = null,
}) => (
  <>
    {isLoading ? (
      <Skeleton containerClassName="flex items-center" height={30} width={200} />
    ) : (
      <div className={styles.root}>
        {prefix && <div className={styles.rootPrefix}>{prefix}</div>}
        <Typography className={styles.rootContent} component="h2" variant="category">
          {children}
        </Typography>
        {suffix && <div className={styles.rootSuffix}>{suffix}</div>}
      </div>
    )}
  </>
)

export default AccordionTitle
