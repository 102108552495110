import clsx from 'classnames'
import { addDays, format, isSameDay, isSameMonth, isWithinInterval } from 'date-fns'
import { cs, de, enGB, ru } from 'date-fns/locale'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { getDateKeysForCache, getRangeForCalendarDatesRequest } from '@utils/dates'
import { DATE_FORMAT_YYYY_MM_DD } from '../../constants'
import { getPriceNumber, getPriceOrNull, useCloseWithEscKey } from '../../helpers'
import { useCalendarCache } from '../../hooks/useCalendarCache'
import { useQueryParams, VISIT_END_PARAM, VISIT_START_PARAM } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import {
  CalendarModeEnum,
  CalendarStateEnum,
  ICurrency,
  IDate,
  IDateCheckout,
  IDatesCheckoutParamsQuery,
  IDatesParamsQuery,
  ILanguage,
  ITravelDate,
  LanguageEnum,
} from '../../store/data'
import FormattedPrice from '../FormattedPrice'
import Skeleton from '../Skeleton'

export const useLocale = () => {
  const language = useBookingFormStore((state) => state.language)

  if (language === LanguageEnum.CS) {
    return cs
  }
  if (language === LanguageEnum.DE) {
    return de
  }
  if (language === LanguageEnum.RU) {
    return ru
  }
  if (language === LanguageEnum.EN) {
    return enGB
  }

  return de
}

export const useCalendarControls = () => {
  const setCalendarTravelDate = useBookingFormStore((state) => state.setCalendarTravelDate)
  const setTravelDate = useBookingFormStore((state) => state.setTravelDate)
  const calendarTravelDate = useBookingFormStore((state) => state.calendar.travelDate)

  const onSelect = React.useCallback(
    (travelDate: ITravelDate) => {
      setCalendarTravelDate(travelDate)
    },
    [setCalendarTravelDate]
  )

  const onClickAway = React.useCallback(() => {
    if (calendarTravelDate.from || calendarTravelDate.to) {
      setTravelDate({ from: null, to: null })
      onSelect({ from: null, to: null })
    }
  }, [calendarTravelDate.from, calendarTravelDate.to, setTravelDate, onSelect])

  useCloseWithEscKey(onClickAway)

  return {
    onClickAway,
    onSelect,
  }
}

export const useCalendar = () => {
  const { dateRangesCacheKeys: dateRangesCacheKeysSelector } = useCalendarCache()

  const lowestPrice = useBookingFormStore((state) => state.lowestPrice)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const setTravelDate = useBookingFormStore((state) => state.setTravelDate)
  const destination = useBookingFormStore((state) => state.destination)
  const hotel = useBookingFormStore((state) => state.hotel)
  const roomType = useBookingFormStore((state) => state.roomType)
  const visitType = useBookingFormStore((state) => state.visitType)
  const calendarDate = useBookingFormStore((state) => state.calendar.date)
  const calendarTravelDate = useBookingFormStore((state) => state.calendar.travelDate)
  const calendarPrice = useBookingFormStore((state) => state.calendar.price)
  const calendarState = useBookingFormStore((state) => state.calendar.state)
  const calendarMode = useBookingFormStore((state) => state.calendar.mode)
  const isDatesFetching = useBookingFormStore((state) => state.calendar.isFetching)
  const datesCheckout = useBookingFormStore((state) => state.calendar.checkout)
  const dates = useBookingFormStore((state) => state.calendar.data)
  const setCalendarMode = useBookingFormStore((state) => state.setCalendarMode)
  const loadDates = useBookingFormStore((state) => state.loadDates)
  const loadDatesCheckout = useBookingFormStore((state) => state.loadDatesCheckout)
  const changeCalendarDate = useBookingFormStore((state) => state.changeCalendarDate)
  const changeAccordion = useBookingFormStore((state) => state.changeAccordion)

  const { addQueryParameter } = useQueryParams()
  const { onSelect } = useCalendarControls()

  const onChangeDate = React.useCallback(
    (value: Date) => changeCalendarDate(value),
    [changeCalendarDate]
  )

  const onSelectDate = React.useCallback(
    (date: Date) => {
      if (calendarMode === CalendarModeEnum.FROM_DATE) {
        onSelect({ from: date, to: null })
      } else if (calendarMode === CalendarModeEnum.TO_DATE) {
        onSelect({ from: calendarTravelDate.from, to: date })
      }
    },
    [calendarMode, calendarTravelDate.from, onSelect]
  )

  const updateRouteQuery = ({ from, to }: ITravelDate) => {
    if (!from || !to) return

    const parsedFrom = format(from, DATE_FORMAT_YYYY_MM_DD)
    const parsedTo = format(to, DATE_FORMAT_YYYY_MM_DD)
    addQueryParameter(VISIT_START_PARAM, parsedFrom)
    addQueryParameter(VISIT_END_PARAM, parsedTo)
  }

  const onConfirm = React.useCallback(() => {
    if (calendarTravelDate.from && calendarTravelDate.to) {
      setTravelDate(calendarTravelDate)
        .then(() => updateRouteQuery(calendarTravelDate))
        .then(() => changeAccordion(null))
    }
  }, [calendarTravelDate, setTravelDate, updateRouteQuery])

  // Load days
  React.useEffect(() => {
    const fetchDates = async (params: IDatesParamsQuery) => {
      await loadDates(params)
    }

    if (appInitialized) {
      const isCalendarInitial = calendarState === CalendarStateEnum.INITIAL

      let sendRequest = true
      let dateFrom
      let dateTo
      let dateMiddleCacheKey
      let dateToCacheKey

      if (!isCalendarInitial) {
        const {
          dateFromCalendarDate,
          dateMiddleCalendarDate,
          dateToCalendarDate,
          sendRequest: willSendRequest,
        } = getRangeForCalendarDatesRequest(calendarDate, dateRangesCacheKeysSelector, false)

        const {
          dateFromCalendarDateRequest,
          dateMiddleCalendarDateCacheKey,
          dateToCalendarDateCacheKey,
          dateToCalendarDateRequest,
        } = getDateKeysForCache(dateFromCalendarDate, dateMiddleCalendarDate, dateToCalendarDate)

        dateFrom = dateFromCalendarDateRequest
        dateTo = dateToCalendarDateRequest
        dateMiddleCacheKey = dateMiddleCalendarDateCacheKey
        dateToCacheKey = dateToCalendarDateCacheKey

        sendRequest = willSendRequest
      }

      if (sendRequest) {
        fetchDates({
          date_from: dateFrom,
          date_to: dateTo,
          dateMiddleCalendarDateCacheKey: dateMiddleCacheKey,
          dateToCalendarDateCacheKey: dateToCacheKey,
          destination_id: destination?.id || undefined,
          hotel_id: hotel?.id || undefined,
          initial: isCalendarInitial,
          room_type_id: roomType?.id || undefined,
          visit_type_id: visitType?.id || undefined,
        })
      }
    }
  }, [
    appInitialized,
    calendarDate,
    calendarState,
    loadDates,
    hotel?.id,
    destination?.id,
    roomType?.id,
    visitType?.id,
    dateRangesCacheKeysSelector,
  ])

  // Load end days and switch calendar mode
  React.useEffect(() => {
    const fetchDatesCheckout = async (params: IDatesCheckoutParamsQuery) => {
      await loadDatesCheckout(params)
    }

    if (appInitialized && calendarTravelDate.from && calendarTravelDate.to === null) {
      setCalendarMode(CalendarModeEnum.TO_DATE)

      fetchDatesCheckout({
        hotel_id: hotel?.id || undefined,
        room_type_id: roomType?.id || undefined,
        start_date: format(calendarTravelDate.from, DATE_FORMAT_YYYY_MM_DD),
        visit_type_id: visitType?.id || undefined,
      })
    } else if (appInitialized) {
      setCalendarMode(CalendarModeEnum.FROM_DATE)
    }
  }, [
    appInitialized,
    calendarTravelDate.from,
    calendarTravelDate.to,
    loadDatesCheckout,
    setCalendarMode,
    hotel?.id,
    destination?.id,
    roomType?.id,
    visitType?.id,
  ])

  return {
    checkouts: datesCheckout,
    data: dates,
    date: calendarDate,
    isFetching: isDatesFetching,
    isFinalPrice: Boolean(
      visitType && hotel && roomType && calendarTravelDate?.from && calendarTravelDate?.to
    ),
    lowestPrice,
    mode: calendarMode,
    onChangeDate,
    onConfirm,
    onSelectDate,
    price: calendarPrice,
    travelDate: calendarTravelDate,
  }
}

const generateDatesForCurrentWeek = (
  date: Date,
  data: IDate[] = [],
  checkouts: IDateCheckout[] = [],
  mode: CalendarModeEnum,
  travelDate: ITravelDate,
  activeDate: Date,
  onSelectDate: (date: Date) => void,
  onClickAway: () => void,
  isFetching = false,
  currency: ICurrency,
  language: ILanguage
): JSX.Element[] => {
  let currentDate = date
  const week = []

  for (let day = 0; day < 7; day++) {
    const cloneDate = currentDate

    const currentDateFormat = format(currentDate, DATE_FORMAT_YYYY_MM_DD)

    const findData = data.find((item) => item.date === currentDateFormat)

    const notNullPrice = getPriceOrNull(findData?.price, 'amount', language)

    const isSameCurrentMonth = isSameMonth(currentDate, activeDate)
    const isAvailabilityWithPrice =
      findData && findData?.price && notNullPrice && isSameCurrentMonth
    const isNotAvailable = findData?.availability === 'NOT_AVAILABLE'

    const isDateDisabled =
      !findData ||
      !isSameCurrentMonth ||
      (mode === CalendarModeEnum.FROM_DATE && !findData?.selectable) ||
      (mode === CalendarModeEnum.TO_DATE &&
        checkouts.filter((item) => item.date === currentDateFormat).length == 0)

    week.push(
      <div
        className={clsx('day', {
          available: findData?.availability === 'AVAILABLE' && isSameCurrentMonth,
          demand: findData?.availability === 'ON_DEMAND' && isSameCurrentMonth,
          disabledDate: isDateDisabled,
          from: travelDate.from ? isSameDay(currentDate, travelDate.from) : false,
          isNotSameMonth: !isSameCurrentMonth,
          notAvailable: isNotAvailable && isSameCurrentMonth,
          select:
            travelDate.from && travelDate.to
              ? isWithinInterval(currentDate, { end: travelDate.to, start: travelDate.from })
              : false,
          to: travelDate.to ? isSameDay(currentDate, travelDate.to) : false,
          today: isSameDay(currentDate, new Date()),
        })}
        key={currentDateFormat}
        onClick={(event) => {
          event.stopPropagation()

          if (isDateDisabled) {
            onClickAway()
          } else {
            onSelectDate(cloneDate)
          }
        }}
      >
        <span>{format(currentDate, 'd')}</span>
        {isFetching && isSameCurrentMonth && <Skeleton width={32} />}
        {!isFetching && (
          <div className="price">
            {isAvailabilityWithPrice ? (
              <>
                {!(findData.price?.is_final || false) && (
                  <span className="mr-0.5">
                    <FormattedMessage
                      defaultMessage="from"
                      description="from prefix in price in day on the calendar"
                    />
                  </span>
                )}
                <span>
                  <FormattedPrice value={getPriceNumber(findData.price, 'amount', language)} />
                </span>
              </>
            ) : (
              <>
                {isNotAvailable ? (
                  <span>
                    <FormattedMessage
                      defaultMessage="N/A"
                      description="Description for not available pill"
                    />
                  </span>
                ) : (
                  <div className="h-4"></div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    )
    currentDate = addDays(currentDate, 1)
  }
  return week
}

export const getWeeks = (
  startDate: Date,
  endDate: Date,
  data: IDate[] = [],
  checkouts: IDateCheckout[] = [],
  mode: CalendarModeEnum,
  travelDate: ITravelDate,
  activeDate: Date,
  onSelectDate: (date: Date) => void,
  onClickAway: () => void,
  isFetching = false,
  currency: ICurrency,
  language: ILanguage
): JSX.Element[][] => {
  let currentDate = startDate
  const allWeeks = []
  while (currentDate <= endDate) {
    allWeeks.push(
      generateDatesForCurrentWeek(
        currentDate,
        data,
        checkouts,
        mode,
        travelDate,
        activeDate,
        onSelectDate,
        onClickAway,
        isFetching,
        currency,
        language
      )
    )
    currentDate = addDays(currentDate, 7)
  }
  return allWeeks
}
