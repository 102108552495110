import { format, isDate } from 'date-fns'
import * as React from 'react'
import { ITravelDate } from '../../store/data'
import AccordionTitle from '../AccordionTitle'
import { useLocale } from './helpers'

export type TravelDateNameProps = {
  travelDate: ITravelDate
}

const TravelDateName: React.FunctionComponent<TravelDateNameProps> = ({ travelDate }) => {
  const locale = useLocale()
  if (!isDate(travelDate.from) || !isDate(travelDate.to)) {
    return null
  }

  return (
    <AccordionTitle>
      {format(travelDate.from as Date, 'do LLLL', { locale })}
      {' - '}
      {format(travelDate.to as Date, 'do LLLL', { locale })}
    </AccordionTitle>
  )
}

export default TravelDateName
