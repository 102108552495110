import * as React from 'react'

type StarIconProps = {
  size?: number
}

const StarIcon: React.FunctionComponent<StarIconProps> = ({ size = 16 }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99967 1.33331L10.0597 5.50665L14.6663 6.17998L11.333 9.42665L12.1197 14.0133L7.99967 11.8466L3.87967 14.0133L4.66634 9.42665L1.33301 6.17998L5.93967 5.50665L7.99967 1.33331Z"
      fill="currentColor"
    />
  </svg>
)

export default StarIcon
