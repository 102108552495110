import * as React from 'react'
import { useEffect } from 'react'
import CalendarAccordion from '@components/CalendarAccordion'
import Claim from '@components/Claim'
import DestinationAccordion from '@components/DestinationAccordion'
import GuestAccordion from '@components/GuestAccordion'
import HotelAccordion from '@components/HotelAccordion'
import HotelDialog from '@components/HotelDialog'
import PriceInfo from '@components/PriceInfo'
import RoomTypeAccordion from '@components/RoomTypeAccordion'
import RoomTypeDialog from '@components/RoomTypeDialog'
import ScrollToWrapper from '@components/ScrollToWrapper'
import VisitTypeCategoriesAccordion from '@components/VisitTypeCategoriesAccordion'
import VisitTypeDialog from '@components/VisitTypeDialog'
import { getDefaultDestinationIdOrNull } from '@utils/destination'
import { useGoogleTagManager } from '../helpers'
import { useAccordion } from '../hooks/useAccordion'
import { useAffiliate } from '../hooks/useAffiliate'
import { useCalendarAccordion } from '../hooks/useCalendarAccordion'
import { useLoadBookingForm } from '../hooks/useLoadBookingForm'
import { useQueryParams } from '../hooks/useQueryParams'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'

const Home: React.FunctionComponent = () => {
  useGoogleTagManager()
  useAffiliate()
  useLoadBookingForm()

  // load query params for the first time when the page is mounted
  const { routerQueryParams } = useQueryParams()

  const defaultDestination = getDefaultDestinationIdOrNull()

  const { resolveAccordionFromRouteQuery } = useAccordion()

  useCalendarAccordion()

  const appInitializedAndValidated = useBookingFormStore(
    (state) => state.appInitializedAndValidated
  )
  const accordion = useBookingFormStore((state) => state.accordion)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const languageInitialized = useBookingFormStore((state) => state.languageInitialized)
  const changeAccordion = useBookingFormStore((state) => state.changeAccordion)
  const setStep = useBookingFormStore((state) => state.setStep)

  React.useEffect(() => {
    setStep(1)
  }, [setStep])

  // handles accordion when changing booking steps
  useEffect(() => {
    if (appInitialized && languageInitialized && appInitializedAndValidated) {
      const accordion = resolveAccordionFromRouteQuery()
      changeAccordion(accordion)
    }
  }, [
    appInitialized,
    languageInitialized,
    routerQueryParams.guest,
    routerQueryParams.destination,
    routerQueryParams.visitType,
    routerQueryParams.hotel,
    routerQueryParams.roomType,
    routerQueryParams.visitStart && routerQueryParams.visitEnd,
    appInitializedAndValidated,
  ])

  const selectedAccordionId = accordion?.toString() || '0'

  return (
    <>
      <Claim />
      <div className="my-6 md:mt-12 md:mb-0">
        <div
          style={{
            ...(defaultDestination && { display: 'none' }),
          }}
        >
          <ScrollToWrapper
            element={<DestinationAccordion />}
            id="0"
            selectedId={selectedAccordionId}
          />
        </div>
        <ScrollToWrapper element={<GuestAccordion />} id="1" selectedId={selectedAccordionId} />
        <ScrollToWrapper element={<HotelAccordion />} id="2" selectedId={selectedAccordionId} />
        <ScrollToWrapper
          element={<VisitTypeCategoriesAccordion />}
          id="3"
          selectedId={selectedAccordionId}
        />
        <ScrollToWrapper element={<RoomTypeAccordion />} id="4" selectedId={selectedAccordionId} />
        <ScrollToWrapper element={<CalendarAccordion />} id="5" selectedId={selectedAccordionId} />
      </div>
      <PriceInfo />
      <VisitTypeDialog />
      <HotelDialog />
      <RoomTypeDialog />
    </>
  )
}

export default Home
