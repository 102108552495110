import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import style from './Claim.module.scss'
import Typography from './Typography'

const Claim: React.FunctionComponent = () => (
  <Typography className={style.root} component="h1" variant="headline">
    <FormattedMessage
      defaultMessage="Book Your Dream{br}Spa retreat!"
      description="Claim for a call to action on the main page"
      values={{ br: <br /> }}
    />
  </Typography>
)

export default Claim
