import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useCalendarAccordion } from '../../hooks/useCalendarAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum } from '../../store/data'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import Calendar from './Calendar'
import TravelDateName from './TravelDateName'

const CalendarAccordion: React.FunctionComponent = () => {
  const accordion = useBookingFormStore((state) => state.accordion)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const hotel = useBookingFormStore((state) => state.hotel)
  const roomType = useBookingFormStore((state) => state.roomType)
  const visitType = useBookingFormStore((state) => state.visitType)
  const travelDate = useBookingFormStore((state) => state.travelDate)
  const changeAccordion = useBookingFormStore((state) => state.changeAccordion)

  const { routerQueryParams } = useQueryParams()
  const { removeTravelDate } = useCalendarAccordion()

  const isLoading = isFetching && (!!routerQueryParams.visitStart || !!routerQueryParams.visitEnd)
  const isActive = isLoading || (!!travelDate?.from && !!travelDate.to)
  const isDisabled = !hotel || !roomType || !visitType

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={() => removeTravelDate()}
      onClose={() => changeAccordion(null)}
      onOpen={() => changeAccordion(AccordionEnum.CALENDAR)}
      open={accordion == AccordionEnum.CALENDAR}
      title={
        travelDate.from && travelDate.to ? (
          <TravelDateName travelDate={travelDate} />
        ) : (
          <AccordionTitle isLoading={isLoading}>
            <FormattedMessage defaultMessage="Date" description="Calendar accordion title" />
          </AccordionTitle>
        )
      }
    >
      <Calendar />
    </Accordion>
  )
}

export default CalendarAccordion
