import * as React from 'react'
import { getPriceOrNull } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { Identificator, IHotel } from '../../store/data'
import TwoColumnsGrid from '../TwoColumnsGrid'
import HotelItem from './HotelItem'
import HotelSkeleton from './HotelSkeleton'

type HotelsProps = {
  hotels: IHotel[]
  onLoadHotel: (hotelId: Identificator) => void
  onSelect: (hotel: IHotel) => void
  selectedHotel?: IHotel | null
}

const Hotels: React.FunctionComponent<HotelsProps> = ({
  hotels,
  onLoadHotel,
  onSelect,
  selectedHotel = null,
}) => {
  const language = useBookingFormStore((state) => state.language)
  const isFetchingHotels = useBookingFormStore((state) => state.isFetchingHotels)
  const setLoadingImages = useBookingFormStore((state) => state.setLoadingImages)

  const data = hotels.filter(
    (item) => getPriceOrNull(item.lowest_price, 'amount_per_night', language) !== null
  )

  React.useEffect(() => {
    const totalImageCount = data.filter((hotel) => !!hotel.image).length
    setLoadingImages(totalImageCount)
  }, [data, setLoadingImages])

  return (
    <TwoColumnsGrid>
      {data.map((item) => {
        return (
          <HotelItem
            data={item}
            key={item.id}
            onLoadHotel={() => onLoadHotel(item.id)}
            onSelect={() => onSelect(item)}
            selected={item?.id === selectedHotel?.id}
          />
        )
      })}
      {isFetchingHotels && Array.from(Array(6).keys()).map((item) => <HotelSkeleton key={item} />)}
    </TwoColumnsGrid>
  )
}

export default Hotels
