import * as React from 'react'
import { Element, scroller } from 'react-scroll'

interface AccordionWrapperProps {
  element: React.ReactElement
  id: string
  selectedId: string
}

const ScrollToWrapper: React.FunctionComponent<React.PropsWithChildren<AccordionWrapperProps>> = ({
  element,
  id,
  selectedId,
}) => {
  React.useEffect(() => {
    if (selectedId !== id) return

    scroller.scrollTo(selectedId, {
      duration: 1000,
      smooth: 'easeInOutQuart',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])

  return <Element name={id}>{element}</Element>
}

export default ScrollToWrapper
