import { useEffect, useState } from 'react'
import { emailValidationSchema } from '../forms/guests/validation'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { AFFIL_USER_NAME, useQueryParams } from './useQueryParams'

export const useAffiliate = () => {
  const [affiliateCacheChecked, setAffiliateCacheChecked] = useState(false)
  const { removeQueryParameter, routerQueryParams } = useQueryParams()

  const affiliateUsername = useBookingFormStore((state) => state.affiliate.affilUserName)
  const setAffiliateUsername = useBookingFormStore((state) => state.setAffiliateUsername)
  const clearExpiredAffiliateData = useBookingFormStore((state) => state.clearExpiredAffiliateData)

  useEffect(() => {
    clearExpiredAffiliateData()
    setAffiliateCacheChecked(true)
  }, [clearExpiredAffiliateData])

  useEffect(() => {
    if (affiliateCacheChecked && routerQueryParams?.affilUserName && affiliateUsername === null) {
      try {
        emailValidationSchema.validateSync(routerQueryParams.affilUserName)

        setAffiliateUsername(routerQueryParams.affilUserName)
      } catch (error) {
        removeQueryParameter(AFFIL_USER_NAME)
      }
    }
  }, [routerQueryParams?.affilUserName, affiliateUsername, affiliateCacheChecked])
}
