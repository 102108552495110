import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAccordion } from '../../hooks/useAccordion'
import { useHotelAccordion } from '../../hooks/useHotelAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum, Identificator } from '../../store/data'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import HotelName from './HotelName'
import Hotels from './Hotels'

const HotelAccordion: React.FunctionComponent = () => {
  const accordion = useBookingFormStore((state) => state.accordion)
  const destination = useBookingFormStore((state) => state.destination)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const hotel = useBookingFormStore((state) => state.hotel)
  const hotels = useBookingFormStore((state) => state.hotels)
  const loadHotel = useBookingFormStore((state) => state.loadHotel)

  const { routerQueryParams } = useQueryParams()
  const { onChangeAccordion } = useAccordion()
  const { onChangeHotel } = useHotelAccordion()

  const onLoadHotel = (hotelId: Identificator) => loadHotel(hotelId)

  const isLoading = !!routerQueryParams.hotel && isFetching
  const isActive = isLoading || !!hotel
  const isDisabled = !destination

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={async () => await onChangeHotel(null)}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.HOTELS)}
      open={accordion === AccordionEnum.HOTELS}
      title={
        hotel?.name ? (
          <HotelName hotel={hotel} />
        ) : (
          <AccordionTitle isLoading={isLoading}>
            <FormattedMessage defaultMessage="Hotel" description="Hotel accordion title" />
          </AccordionTitle>
        )
      }
    >
      <Hotels
        hotels={hotels}
        onLoadHotel={onLoadHotel}
        onSelect={onChangeHotel}
        selectedHotel={hotel}
      />
    </Accordion>
  )
}

export default HotelAccordion
