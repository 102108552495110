import { getKeyLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { IRoomType } from '../store/data'
import { GUEST_PARAM, ROOM_TYPE_PARAM, useQueryParams } from './useQueryParams'

export const useRoomTypeAccordion = () => {
  const language = useBookingFormStore((state) => state.language)
  const changeRoomType = useBookingFormStore((state) => state.changeRoomType)
  const changeGuest = useBookingFormStore((state) => state.changeGuest)
  const guest = useBookingFormStore((state) => state.guest)

  const { addQueryParameter, removeQueryParameter, routerQueryParams } = useQueryParams()

  const updateRouteQuery = (value: IRoomType | null) => {
    const roomTypeName = value?.category[getKeyLang(language)]

    if (roomTypeName) {
      addQueryParameter(ROOM_TYPE_PARAM, roomTypeName).then(() => {
        // if no guests were selected and room type has beds count, set guests count to beds count
        if (
          (!guest ||
            typeof routerQueryParams?.guest === 'undefined' ||
            routerQueryParams.guest === null) &&
          value?.beds
        ) {
          addQueryParameter(GUEST_PARAM, `${value.beds}`).then(() => {
            changeGuest(value.beds)
          })
        }
      })
    } else {
      removeQueryParameter(ROOM_TYPE_PARAM)
    }
  }

  const validateRoomType = (value: IRoomType | null) => {
    updateRouteQuery(value)
  }

  const onChangeRoomType = (value: IRoomType | null) => {
    changeRoomType(value)
    validateRoomType(value)
  }

  const onInitRoomTypeValidation = (value: IRoomType | null) => {
    validateRoomType(value)
  }

  return {
    onChangeRoomType,
    onInitRoomTypeValidation,
  }
}
