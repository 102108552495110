import { useBookingFormStore } from '../providers/BookingFormStoreProvider'

export const useIsAllBookingParams = () => {
  const guest = useBookingFormStore((state) => state.guest)
  const hotel = useBookingFormStore((state) => state.hotel)
  const roomType = useBookingFormStore((state) => state.roomType)
  const travelDate = useBookingFormStore((state) => state.travelDate)
  const visitType = useBookingFormStore((state) => state.visitType)

  return Boolean(guest && visitType && hotel && roomType && travelDate?.from && travelDate?.to)
}
