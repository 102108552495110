import { getLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { IDestination } from '../store/data'
import {
  DESTINATION_PARAM,
  HOTEL_PARAM,
  ROOM_TYPE_PARAM,
  useQueryParams,
  VISIT_END_PARAM,
  VISIT_START_PARAM,
  VISIT_TYPE_PARAM,
} from './useQueryParams'
import { useVisitTypeCategoriesAccordion } from './useVisitTypeCategoriesAccordion'

export const useDestinationsAccordion = () => {
  const language = useBookingFormStore((state) => state.language)
  const destinations = useBookingFormStore((state) => state.destinations)
  const hotel = useBookingFormStore((state) => state.hotel)
  const changeVisitType = useBookingFormStore((state) => state.changeVisitType)
  const changeDestination = useBookingFormStore((state) => state.changeDestination)
  const changeHotel = useBookingFormStore((state) => state.changeHotel)
  const changeRoomType = useBookingFormStore((state) => state.changeRoomType)

  const { addQueryParameter, removeQueryParametersArray } = useQueryParams()
  const { removeRoomHotel } = useVisitTypeCategoriesAccordion()

  const removeRelatedDestinationQueryParams = () => {
    changeVisitType(null)
    changeHotel(null)
    changeRoomType(null)
    removeQueryParametersArray([
      DESTINATION_PARAM,
      VISIT_TYPE_PARAM,
      HOTEL_PARAM,
      ROOM_TYPE_PARAM,
      VISIT_START_PARAM,
      VISIT_END_PARAM,
    ])
  }

  const updateRouteQuery = (destination: IDestination | null) => {
    if (destination) {
      addQueryParameter(DESTINATION_PARAM, getLang(destination.name, language))
    } else {
      removeRelatedDestinationQueryParams()
    }
  }

  const validateDestinationRelations = (destination: IDestination) => {
    const allDestinations = destinations ?? []
    if (
      allDestinations.some(
        (destinationItem) => Number(destinationItem.id) === Number(destination.id)
      )
    ) {
      if (hotel && Number(hotel.destination.id) !== Number(destination.id)) {
        removeRoomHotel()
      }
    } else {
      removeRelatedDestinationQueryParams()
    }
  }

  const onChangeDestination = (value: IDestination | null) => {
    if (value) {
      validateDestinationRelations(value)
    }
    changeDestination(value)
    updateRouteQuery(value)
  }

  const onInitDestinationValidation = (destination: IDestination | null) => {
    if (destination) {
      validateDestinationRelations(destination)
    } else {
      removeRelatedDestinationQueryParams()
    }
  }

  return {
    onChangeDestination,
    onInitDestinationValidation,
  }
}
