import * as React from 'react'
import { getLang } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IRoomType } from '../../store/data'
import AccordionTitle from '../AccordionTitle'

export type RoomTypeProps = {
  roomType: IRoomType
}

const RoomTypeName: React.FunctionComponent<RoomTypeProps> = ({ roomType }) => {
  const language = useBookingFormStore((state) => state.language)

  return <AccordionTitle>{getLang(roomType.category, language)}</AccordionTitle>
}

export default RoomTypeName
