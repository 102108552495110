import clsx from 'classnames'
import * as React from 'react'
import styles from './Tag.module.scss'

type TagProps = {
  variant?: 'default' | 'primary'
}

const Tag: React.FC<React.PropsWithChildren<TagProps>> = ({ children, variant = 'default' }) => (
  <span
    className={clsx(styles.root, {
      [styles.rootDefault]: variant === 'default',
      [styles.rootPrimary]: variant === 'primary',
    })}
  >
    {children}
  </span>
)

export default Tag
