import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '../Button'

type ButtonConfirmType = {
  disabled: boolean
  onConfirm: () => void
}

const ButtonConfirm: React.FunctionComponent<ButtonConfirmType> = ({ disabled, onConfirm }) => (
  <Button
    className="py-4 px-6 md:py-2 md:px-8"
    disabled={disabled}
    onClick={(event) => {
      event.stopPropagation()
      onConfirm()
    }}
    variant="contained"
  >
    <FormattedMessage defaultMessage="Confirm" description="Calendar confirm button" />
  </Button>
)

export default ButtonConfirm
