import * as React from 'react'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { getValidGuestNumberOrNull } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useGuestsAccordion } from '../../hooks/useGuestsAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum } from '../../store/data'
import Accordion from '../Accordion'
import AccordionTitle from '../AccordionTitle'
import Guests from './Guests'

const GuestAccordion: React.FunctionComponent = () => {
  const { routerQueryParams } = useQueryParams()

  const accordion = useBookingFormStore((state) => state.accordion)
  const guest = useBookingFormStore((state) => state.guest)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const destination = useBookingFormStore((state) => state.destination)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const changeGuest = useBookingFormStore((state) => state.changeGuest)
  const isDeletingQueryParameter = useBookingFormStore((state) => state.isDeletingQueryParameter)

  const isLoading = isFetching && !!routerQueryParams.guest
  const isActive = isLoading || !!guest
  const isDisabled = !destination

  const { onChangeGuest } = useGuestsAccordion()
  const { onChangeAccordion } = useAccordion()

  useEffect(() => {
    const guestFromQueryParameters = getValidGuestNumberOrNull(routerQueryParams.guest)

    if (
      appInitialized &&
      !isDeletingQueryParameter &&
      !guest &&
      guestFromQueryParameters !== null
    ) {
      // Covers the situation where the booking form is loading with no guests selected, but a customer selects a guest in the meantime.
      changeGuest(guestFromQueryParameters)
    }
  }, [appInitialized, guest, changeGuest, routerQueryParams.guest, isDeletingQueryParameter])

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={async () => await onChangeGuest(null)}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.GUESTS)}
      open={accordion === AccordionEnum.GUESTS}
      title={
        <AccordionTitle isLoading={isLoading}>
          <FormattedMessage
            defaultMessage="{count, plural, =0 {Guests} one {# Guest} other {# Guests}}"
            description="Guest accordion title"
            values={{ count: parseInt(String(guest || 0)) }}
          />
        </AccordionTitle>
      }
    >
      <Guests onChange={onChangeGuest} value={guest} />
    </Accordion>
  )
}

export default GuestAccordion
