import { addDays, endOfMonth, format, isPast, startOfMonth } from 'date-fns'
import * as React from 'react'
import BasicButton from '../BasicButton'
import NextIcon from '../NextIcon'
import PreviousIcon from '../PreviousIcon'

type CalendarControlsProps = {
  date: Date
  isFetching: boolean
  onChangeDate: (value: Date) => void
}

const CalendarControls: React.FunctionComponent<CalendarControlsProps> = ({
  date,
  isFetching,
  onChangeDate,
}) => {
  return (
    <div className="calendar-controls">
      <BasicButton
        className="control-previous"
        disabled={isPast(addDays(startOfMonth(date), -1)) || isFetching}
        onClick={(event) => {
          event.stopPropagation()
          onChangeDate(addDays(startOfMonth(date), -1))
        }}
      >
        <PreviousIcon />
      </BasicButton>
      <span className="current-year">{format(startOfMonth(date), 'YYY')}</span>
      <BasicButton
        className="control-next"
        disabled={isFetching}
        onClick={(event) => {
          event.stopPropagation()
          onChangeDate(addDays(endOfMonth(date), 1))
        }}
      >
        <NextIcon />
      </BasicButton>
    </div>
  )
}

export default CalendarControls
