interface IRegexes {
  EMAIL: RegExp
  PHONE: RegExp
}

const REGEXES: IRegexes = {
  EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  PHONE: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
}

export default REGEXES
