import * as React from 'react'
import { getLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import Dialog from './Dialog'
import Markdown from './Markdown'

const VisitTypeDialog: React.FunctionComponent = () => {
  const language = useBookingFormStore((state) => state.language)
  const setVisitTypeDialogData = useBookingFormStore((state) => state.setVisitTypeDialogData)
  const visitTypeDialog = useBookingFormStore((state) => state.visitTypeDialog)

  const { data } = visitTypeDialog

  return (
    <Dialog
      onClose={() => setVisitTypeDialogData(null)}
      open={Boolean(data)}
      title={getLang(data?.name, language) || ''}
    >
      <Markdown source={getLang(data?.description, language) || ''} />
    </Dialog>
  )
}

export default VisitTypeDialog
