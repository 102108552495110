import { useEffect } from 'react'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { useQueryParams, VISIT_END_PARAM, VISIT_START_PARAM } from './useQueryParams'

export const useCalendarAccordion = () => {
  const { removeQueryParametersArray, routerQueryParams } = useQueryParams()

  const isDeletingQueryParameter = useBookingFormStore((state) => state.isDeletingQueryParameter)
  const travelDate = useBookingFormStore((state) => state.travelDate)
  const resetCalendar = useBookingFormStore((state) => state.resetCalendar)
  const appInitialized = useBookingFormStore((state) => state.appInitialized)
  const step = useBookingFormStore((state) => state.stepper.step)

  useEffect(() => {
    const handleQueryParams = async () => {
      if (
        appInitialized &&
        step === 1 &&
        !travelDate.from &&
        !travelDate.to &&
        routerQueryParams.visitStart &&
        routerQueryParams.visitEnd &&
        !isDeletingQueryParameter
      ) {
        await removeQueryParametersArray([VISIT_START_PARAM, VISIT_END_PARAM])
      }
    }

    handleQueryParams()
  }, [
    travelDate.from,
    travelDate.to,
    routerQueryParams.visitStart,
    routerQueryParams.visitEnd,
    step,
    isDeletingQueryParameter,
  ])

  const removeTravelDate = () => {
    resetCalendar()
  }

  return {
    removeTravelDate,
  }
}
