import { AccordionEnum } from '../store/data'

export const resolveNextAccordion = (
  currentAccordion: AccordionEnum,
  accordionValuesState: {
    destination: boolean
    guest: boolean
    hotel: boolean
    roomType: boolean
    visitType: boolean
  }
) => {
  if (currentAccordion === AccordionEnum.HOTELS) return AccordionEnum.ROOMS

  const { destination, guest, hotel, roomType, visitType } = accordionValuesState

  const accordionValues = [destination, guest, visitType, hotel, roomType]
  const nextAccordion = currentAccordion + 1
  const accordionsAfter = accordionValues.slice(nextAccordion)
  const emptyAccordion = accordionsAfter.findIndex((accordion) => !accordion)

  return emptyAccordion === -1 ? AccordionEnum.CALENDAR : nextAccordion + emptyAccordion
}
