import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import { IHotel } from '../store/data'
import {
  HOTEL_PARAM,
  ROOM_TYPE_PARAM,
  useQueryParams,
  VISIT_END_PARAM,
  VISIT_START_PARAM,
  VISIT_TYPE_PARAM,
} from './useQueryParams'

export const useHotelAccordion = () => {
  const guest = useBookingFormStore((state) => state.guest)
  const roomType = useBookingFormStore((state) => state.roomType)
  const changeHotel = useBookingFormStore((state) => state.changeHotel)
  const changeRoomType = useBookingFormStore((state) => state.changeRoomType)
  const changeVisitType = useBookingFormStore((state) => state.changeVisitType)
  const changeVisitTypeCategory = useBookingFormStore((state) => state.changeVisitTypeCategory)

  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()

  const updateRouteQuery = async (value: string | undefined) => {
    if (value) {
      await addQueryParameter(HOTEL_PARAM, value)
    } else {
      await removeQueryParameter(HOTEL_PARAM)
    }
  }

  const validateHotelRoomType = async (currentHotelValue: IHotel | null) => {
    if (roomType) {
      // e.g. hotel has been changed, and we have to check if a room type is included and if beds count fits guests number
      const idsRoomTypes = (currentHotelValue?.roomTypes || [])
        .filter((item) => {
          return guest !== null ? item.beds === guest : true
        })
        .map((item) => item.id)

      if (!idsRoomTypes.includes(roomType.id)) {
        changeRoomType(null)

        await removeQueryParametersArray([ROOM_TYPE_PARAM, VISIT_START_PARAM, VISIT_END_PARAM])
      }
    } else {
      await removeQueryParametersArray([ROOM_TYPE_PARAM, VISIT_START_PARAM, VISIT_END_PARAM])
    }
  }

  const onChangeHotel = async (value: IHotel | null) => {
    changeHotel(value)
    changeVisitType(null)
    changeVisitTypeCategory(null)

    await updateRouteQuery(value?.name)
    await removeQueryParametersArray([
      ROOM_TYPE_PARAM,
      VISIT_TYPE_PARAM,
      VISIT_START_PARAM,
      VISIT_END_PARAM,
    ])
  }

  const onInitHotelValidation = async (value: IHotel | null) => {
    await validateHotelRoomType(value)
  }

  return {
    onChangeHotel,
    onInitHotelValidation,
  }
}
