import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Accordion from '@components/Accordion'
import AccordionTitle from '@components/AccordionTitle'
import Destinations from '@components/DestinationAccordion/Destinations'
import { getLang } from '../../helpers'
import { useAccordion } from '../../hooks/useAccordion'
import { useDestinationsAccordion } from '../../hooks/useDestinationsAccordion'
import { useQueryParams } from '../../hooks/useQueryParams'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { AccordionEnum } from '../../store/data'

const DestinationAccordion = () => {
  const { onChangeAccordion } = useAccordion()
  const { onChangeDestination } = useDestinationsAccordion()
  const { routerQueryParams } = useQueryParams()

  const language = useBookingFormStore((state) => state.language)
  const accordion = useBookingFormStore((state) => state.accordion)
  const destination = useBookingFormStore((state) => state.destination)
  const isFetching = useBookingFormStore((state) => state.isFetching)
  const destinations = useBookingFormStore((state) => state.destinations)

  const isLoading = isFetching && !!routerQueryParams.destination
  const isActive = isLoading || !!destination
  const isDisabled = isFetching

  const destinationTitle: React.ReactNode = React.useMemo(() => {
    if (destination) {
      return getLang(destination.name, language)
    } else {
      return (
        <FormattedMessage defaultMessage="Destinations" description="Destination accordion title" />
      )
    }
  }, [destination, language])

  return (
    <Accordion
      active={isActive}
      disabled={isDisabled}
      isLoading={isLoading}
      onClear={() => {
        onChangeDestination(null)
      }}
      onClose={() => onChangeAccordion(null)}
      onOpen={() => onChangeAccordion(AccordionEnum.DESTINATION)}
      open={accordion === AccordionEnum.DESTINATION}
      title={<AccordionTitle isLoading={isLoading}>{destinationTitle}</AccordionTitle>}
    >
      <Destinations
        destinations={destinations ?? []}
        onChange={onChangeDestination}
        value={destination}
      />
    </Accordion>
  )
}

export default DestinationAccordion
