import * as React from 'react'
import { getLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import Dialog from './Dialog'
import Markdown from './Markdown'

const HotelDialog: React.FunctionComponent = () => {
  const language = useBookingFormStore((state) => state.language)
  const setHotelDialogData = useBookingFormStore((state) => state.setHotelDialogData)
  const hotelDialog = useBookingFormStore((state) => state.hotelDialog)

  const { data } = hotelDialog

  return (
    <Dialog onClose={() => setHotelDialogData(null)} open={Boolean(data)} title={data?.name || ''}>
      <Markdown source={getLang(data?.description, language) || ''} />
    </Dialog>
  )
}

export default HotelDialog
