import * as React from 'react'

const GuestIcon: React.FunctionComponent = () => (
  <svg fill="none" height="34" viewBox="0 0 30 34" width="30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 0.5C9.219 0.5 4.5 5.219 4.5 11C4.5 14.615 6.345 17.828 9.141 19.7195C3.7905 22.0145 0 27.32 0 33.5H3C3 26.855 8.355 21.5 15 21.5C21.645 21.5 27 26.855 27 33.5H30C30 27.32 26.2095 22.013 20.859 19.718C22.2864 18.755 23.4559 17.4568 24.265 15.9369C25.0741 14.417 25.4982 12.7218 25.5 11C25.5 5.219 20.781 0.5 15 0.5ZM15 3.5C19.1595 3.5 22.5 6.8405 22.5 11C22.5 15.1595 19.1595 18.5 15 18.5C10.8405 18.5 7.5 15.1595 7.5 11C7.5 6.8405 10.8405 3.5 15 3.5Z"
      fill="currentColor"
    />
  </svg>
)

export default GuestIcon
