import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '../Typography'

const Legend = () => (
  <div className="flex items-center flex-wrap">
    <Typography
      className="px-3 py-1 rounded-xl bg-primary-highlight bg-opacity-30 uppercase text-primary-main whitespace-nowrap m-0.5"
      component="span"
      variant="small"
    >
      <FormattedMessage defaultMessage="Available" description="Helper text for Available" />
    </Typography>
    <Typography
      className="px-3 py-1 rounded-xl bg-info-highlight bg-opacity-30 uppercase text-info-main whitespace-nowrap m-0.5"
      variant="small"
    >
      <FormattedMessage defaultMessage="On Demand" description="Helper text for On Demand" />
    </Typography>
    <Typography
      className="px-3 py-1 rounded-xl bg-danger-highlight bg-opacity-30 uppercase text-danger-main whitespace-nowrap m-0.5"
      variant="small"
    >
      <FormattedMessage
        defaultMessage="Not Available"
        description="Helper text for Not Available"
      />
    </Typography>
  </div>
)

export default Legend
