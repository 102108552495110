import * as React from 'react'

type UpIconProps = {
  size?: number
}

const UpIcon: React.FunctionComponent<UpIconProps> = ({ size = 24 }) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 15L12 9L18 15"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default UpIcon
