import clsx from 'classnames'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { getLang } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IVisitCategory, IVisitType } from '../../store/data'
import DownIcon from '../DownIcon'
import UpIcon from '../UpIcon'
import { getVisitTypesFilter } from './helpers'
import VisitTypeCategoryItems from './VisitTypeCategoryItems'

type VisitTypesCategoryProps = {
  onLoadVisitType: (type: IVisitType) => void
  onSelectVisitType: (visitType: IVisitType) => Promise<void>
  onSelectVisitTypeCategory: (visitType: IVisitCategory) => void
  selectVisitType?: IVisitType | null
  selectVisitTypeCategory?: IVisitCategory | null
  visitCategory: IVisitCategory
}

const VisitTypesCategory: React.FunctionComponent<VisitTypesCategoryProps> = ({
  onLoadVisitType,
  onSelectVisitType,
  onSelectVisitTypeCategory,
  selectVisitType = null,
  selectVisitTypeCategory = null,
  visitCategory,
}) => {
  const [show, setShow] = React.useState<boolean>(selectVisitTypeCategory?.id === visitCategory?.id)
  const language = useBookingFormStore((state) => state.language)
  const visitTypes = getVisitTypesFilter(visitCategory.visitTypes, language)

  return (
    <div className="w-full my-2 md:my-4 border-t-2 border-card-default pt-2">
      <div className="select-none flex items-center justify-between">
        <button
          className={clsx('p-2 outline-0 text-left flex items-center justify-between flex-1', {
            'text-muted': !show,
            'text-theme-main': show,
          })}
          onClick={() => setShow((prevState) => !prevState)}
          type="button"
        >
          <div className="flex flex-col justify-center text-left">
            <h3 className="inline text-subcategory">{getLang(visitCategory.name, language)}</h3>
            <span className="text-caption text-muted uppercase">
              <FormattedMessage
                defaultMessage="{count, plural, =0 {No visit type} one {# Visit type} other {# Visit types}}"
                description="Visit type count in Visit type Category component"
                values={{ count: visitTypes.length }}
              />
            </span>
          </div>
          {show ? <DownIcon /> : <UpIcon />}
        </button>
      </div>
      {show && (
        <VisitTypeCategoryItems
          onLoadVisitType={onLoadVisitType}
          onSelectVisitType={onSelectVisitType}
          onSelectVisitTypeCategory={onSelectVisitTypeCategory}
          selectVisitType={selectVisitType}
          visitCategory={visitCategory}
          visitTypes={visitTypes}
        />
      )}
    </div>
  )
}

export default VisitTypesCategory
