import clsx from 'classnames'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReservationButtonTooltipWrapper } from '@components/PriceInfo/ReservationButtonTooltipWrapper'
import BasicButton from '../BasicButton'
import { BookingRequest } from './helpers'
import styles from './ReservationButtons.module.scss'

interface ReservationButtonsProps {
  disabled: boolean
  onBookingRequest: BookingRequest
  onlyAvailableDemand: boolean
}

const ReservationButtons = ({
  disabled,
  onBookingRequest,
  onlyAvailableDemand,
}: ReservationButtonsProps) => (
  <div className={styles.root}>
    <ReservationButtonTooltipWrapper showTooltip={disabled}>
      <BasicButton
        className={clsx(styles.rootButton, styles.rootNonBindingRequest, {
          [styles.rootDisabledButton]: disabled,
        })}
        clearBasicStyle
        disabled={disabled}
        onClick={() => onBookingRequest(true)}
      >
        <FormattedMessage
          defaultMessage="Non-Binding Request"
          description="No binding request button in Reservation Buttons component"
        />
      </BasicButton>
    </ReservationButtonTooltipWrapper>
    {!onlyAvailableDemand && (
      <ReservationButtonTooltipWrapper showTooltip={disabled}>
        <BasicButton
          className={clsx(styles.rootButton, styles.rootBidingRequest, {
            [styles.rootDisabledButton]: disabled,
          })}
          clearBasicStyle
          disabled={disabled}
          onClick={() => onBookingRequest(false)}
        >
          <FormattedMessage
            defaultMessage="Book"
            description="Binding request button for confirm booking in Reservation Buttons component"
          />
        </BasicButton>
      </ReservationButtonTooltipWrapper>
    )}
  </div>
)

export default ReservationButtons
