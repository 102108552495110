import { useMemo } from 'react'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'

export const useCalendarCache = () => {
  const datesCache = useBookingFormStore((state) => state.calendar.datesCache)

  const getCachedMonth = (dateCacheKey: string) => {
    return datesCache.hasOwnProperty(dateCacheKey) ? datesCache[dateCacheKey] : null
  }

  const dateRangesCacheKeys = useMemo(() => Object.keys(datesCache), [datesCache])

  const getCachedDatesForKey = (dateCacheKey: string) => {
    return getCachedMonth(dateCacheKey)
  }

  return { dateRangesCacheKeys, getCachedDatesForKey }
}
