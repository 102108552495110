import { IntlShape } from 'react-intl'
import * as yup from 'yup'
import { getDateOrNull } from '@utils/dates'
import REGEXES from '@utils/regexes'

export const guestsFormValidationCases = {
  birthDateInvalid: 'BIRTH_DATE_INVALID',
  birthDateRequired: 'BIRTH_DATE_REQUIRED',
  cityRequired: 'CITY_REQUIRED',
  countryRequired: 'COUNTRY_REQUIRED',
  dateInvalid: 'DATE_INVALID',
  emailRequired: 'EMAIL_REQUIRED',
  firstNameRequired: 'FIRST_NAME_REQUIRED',
  genderRequired: 'GENDER_REQUIRED',
  incorrectEmail: 'INCORRECT_EMAIL',
  lastNameRequired: 'LAST_NAME_REQUIRED',
  phoneInvalid: 'PHONE_INVALID',
  phoneRequired: 'PHONE_REQUIRED',
  streetNumberRequired: 'STREET_NUMBER_REQUIRED',
  streetRequired: 'STREET_REQUIRED',
  zipCodeRequired: 'ZIP_CODE_REQUIRED',
}

export const birthDateValidationSchema = yup
  .date()
  .nullable()
  .transform(getDateOrNull)
  .max(new Date(), guestsFormValidationCases.dateInvalid)
  .when(['$isDemand', 'isEditing'], {
    is: (isDemand: boolean, isEditing: boolean) => isDemand && !isEditing,
    otherwise: (schema) =>
      schema
        .required(guestsFormValidationCases.birthDateRequired)
        .typeError(guestsFormValidationCases.birthDateInvalid),
    then: (schema) => schema,
  })

export const firstNameValidationSchema = yup
  .string()
  .nullable()
  .when(['$isDemand', 'isEditing'], {
    is: (isDemand: boolean, isEditing: boolean) => isDemand && !isEditing,
    otherwise: (schema) => schema.required(guestsFormValidationCases.firstNameRequired),
    then: (schema) => schema,
  })

export const lastNameValidationSchema = yup
  .string()
  .nullable()
  .when(['$isDemand', 'isEditing'], {
    is: (isDemand: boolean, isEditing: boolean) => isDemand && !isEditing,
    otherwise: (schema) => schema.required(guestsFormValidationCases.lastNameRequired),
    then: (schema) => schema,
  })

export const genderValidationSchema = yup
  .string()
  .nullable()
  .oneOf(['female', 'male'])
  .when(['$isDemand', 'isEditing'], {
    is: (isDemand: boolean, isEditing: boolean) => isDemand && !isEditing,
    otherwise: (schema) => schema.required(guestsFormValidationCases.genderRequired),
    then: (schema) => schema,
  })

export const emailValidationSchema = yup
  .string()
  .required(guestsFormValidationCases.emailRequired)
  .matches(REGEXES.EMAIL, {
    excludeEmptyString: false,
    message: guestsFormValidationCases.incorrectEmail,
  })

export const phoneValidationSchema = yup
  .string()
  .required(guestsFormValidationCases.phoneRequired)
  .matches(REGEXES.PHONE, {
    excludeEmptyString: false,
    message: guestsFormValidationCases.phoneInvalid,
  })

export const getYupValidationErrorTranslationKeyForGuest = (
  intl: IntlShape,
  intlMessageKey?: string
) => {
  const { formatMessage } = intl

  if (!intlMessageKey) return ''

  switch (intlMessageKey) {
    case guestsFormValidationCases.firstNameRequired: {
      return formatMessage({
        defaultMessage: 'First name is required',
        description: 'First name is required in guests',
      })
    }
    case guestsFormValidationCases.lastNameRequired: {
      return formatMessage({
        defaultMessage: 'Last name is required',
        description: 'Last name is required in guests',
      })
    }
    case guestsFormValidationCases.genderRequired: {
      return formatMessage({
        defaultMessage: 'Gender is required',
        description: 'Gender is required in guests',
      })
    }
    case guestsFormValidationCases.dateInvalid: {
      return formatMessage({
        defaultMessage: 'Date is not valid',
        description: 'Date is not valid in guests',
      })
    }
    case guestsFormValidationCases.birthDateRequired: {
      return formatMessage({
        defaultMessage: 'Birth date is required',
        description: 'Birth date is required in guests',
      })
    }
    case guestsFormValidationCases.birthDateInvalid: {
      return formatMessage({
        defaultMessage: 'Invalid date',
        description: 'Invalid date in guests',
      })
    }
    case guestsFormValidationCases.cityRequired: {
      return formatMessage({
        defaultMessage: 'City is required',
        description: 'City is required in Personal form',
      })
    }
    case guestsFormValidationCases.countryRequired: {
      return formatMessage({
        defaultMessage: 'Country is required',
        description: 'Country is required in Personal form',
      })
    }
    case guestsFormValidationCases.emailRequired: {
      return formatMessage({
        defaultMessage: 'Email is required',
        description: 'Email is required in Personal form',
      })
    }
    case guestsFormValidationCases.incorrectEmail: {
      return formatMessage({
        defaultMessage: 'Incorrect email',
        description: 'Email incorrect in Personal form',
      })
    }
    case guestsFormValidationCases.streetNumberRequired: {
      return formatMessage({
        defaultMessage: 'Street number is required',
        description: 'Street number is required in Personal form',
      })
    }
    case guestsFormValidationCases.phoneRequired: {
      return formatMessage({
        defaultMessage: 'Phone is required',
        description: 'Phone is required in Personal form',
      })
    }
    case guestsFormValidationCases.phoneInvalid: {
      return formatMessage({
        defaultMessage: 'Phone number is not valid',
        description: 'Phone is not valid in Personal form',
      })
    }
    case guestsFormValidationCases.streetRequired: {
      return formatMessage({
        defaultMessage: 'Street is required',
        description: 'Street required in Personal form',
      })
    }
    case guestsFormValidationCases.zipCodeRequired: {
      return formatMessage({
        defaultMessage: 'Zip code is required',
        description: 'Zip code is required in Personal form',
      })
    }
    default:
      return ''
  }
}
