import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { getLang, getPriceNumber } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IRoomType } from '../../store/data'
import Card from '../Card/Card'
import Typography from '../Typography'

type RoomTypeItemProps = {
  data: IRoomType
  onLoadRoomType: () => void
  onSelect: React.MouseEventHandler<HTMLButtonElement>
  selected?: boolean
}

const RoomTypeItem: React.FunctionComponent<RoomTypeItemProps> = ({
  data,
  onLoadRoomType,
  onSelect,
  selected = false,
}) => {
  const language = useBookingFormStore((state) => state.language)

  return (
    <Card
      description={
        <Typography className="text-muted uppercase" component="span" variant="small">
          <FormattedMessage
            defaultMessage="{count, plural, =0 {No bed} one {# Bed} other {# Beds}}"
            description="Count button in Room type item component"
            values={{ count: data.beds }}
          />
        </Typography>
      }
      imageUrl={data.image}
      onDetail={onLoadRoomType}
      onSelect={onSelect}
      price={getPriceNumber(data.lowest_price, 'amount_per_night', language)}
      selected={selected}
      title={getLang(data.category, language)}
    />
  )
}

export default RoomTypeItem
