import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import {
  GUEST_PARAM,
  ROOM_TYPE_PARAM,
  useQueryParams,
  VISIT_END_PARAM,
  VISIT_START_PARAM,
} from './useQueryParams'

export const useGuestsAccordion = () => {
  const roomType = useBookingFormStore((state) => state.roomType)
  const changeGuest = useBookingFormStore((state) => state.changeGuest)
  const changeRoomType = useBookingFormStore((state) => state.changeRoomType)
  const setDeletingQueryParameter = useBookingFormStore((state) => state.setDeletingQueryParameter)

  const { addQueryParameter, removeQueryParameter, removeQueryParametersArray } = useQueryParams()

  const removeRoomType = () => {
    removeQueryParameter(ROOM_TYPE_PARAM).then(() => changeRoomType(null))
  }

  const validateRoomTypeForGuest = (value: number) => {
    // remove room type selected as it changes number of beds
    if (roomType?.beds && roomType.beds !== value) {
      removeRoomType()
    }
  }

  const onChangeGuest = async (value: number | null) => {
    if (value) {
      validateRoomTypeForGuest(value)
    }

    // note: This is a workaround to avoid the issue with blinking between guests while removing the guest parameter
    if (value) {
      changeGuest(value)
      await addQueryParameter(GUEST_PARAM, `${value}`).then(() => changeGuest(value))
    } else {
      setDeletingQueryParameter(true)
      changeGuest(null)
      await removeQueryParametersArray([GUEST_PARAM, VISIT_START_PARAM, VISIT_END_PARAM]).then(() =>
        changeGuest(null)
      )
      setDeletingQueryParameter(false)
    }
  }

  const onInitGuestValidation = (value: number | null) => {
    if (value) {
      validateRoomTypeForGuest(value)
    }
  }

  return {
    onChangeGuest,
    onInitGuestValidation,
  }
}
