import clsx from 'classnames'
import * as React from 'react'
import DownIcon from './DownIcon'
import TrashIcon from './TrashIcon'
import UpIcon from './UpIcon'

export interface IAccordionOpener {
  onClose: () => void
  onOpen: () => void
  open?: boolean
}

interface AccordionProps extends IAccordionOpener {
  active?: boolean
  disabled?: boolean
  isLoading?: boolean
  onClear?: () => void
  title: React.ReactElement
}

const Accordion: React.FunctionComponent<React.PropsWithChildren<AccordionProps>> = ({
  active = false,
  children,
  disabled = false,
  isLoading = false,
  onClear,
  onClose,
  onOpen,
  open = false,
  title,
}) => {
  const hasVisibleTrashButton = !open && active && onClear

  return (
    <div
      className={clsx('accordion--component', {
        active,
        disabled,
        open,
      })}
    >
      <div className="header">
        <div
          className={clsx('left-side', {
            loading: isLoading,
            'without-action': !hasVisibleTrashButton,
          })}
        >
          <button
            className={clsx({ active })}
            disabled={disabled}
            onClick={(event) => {
              event.preventDefault()
              open ? onClose() : onOpen()
            }}
            type="button"
          >
            <div className="icon">{open ? <UpIcon /> : <DownIcon />}</div>
            <div className="heading">{title}</div>
          </button>
        </div>
        <div className="right-side">
          {!isLoading && hasVisibleTrashButton && (
            <button onClick={onClear} type="button">
              <TrashIcon />
            </button>
          )}
        </div>
      </div>
      {open && <div className="main-content">{children}</div>}
    </div>
  )
}

export default Accordion
