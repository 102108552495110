import * as React from 'react'

const NextIcon: React.FunctionComponent = () => (
  <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 13L7 7L1 1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)

export default NextIcon
