import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { getLang, getPriceNumber } from '../../helpers'
import { useBookingFormStore } from '../../providers/BookingFormStoreProvider'
import { IVisitCategory, IVisitType } from '../../store/data'
import Card from '../Card'
import TwoColumnsGrid from '../TwoColumnsGrid'
import Typography from '../Typography'
import Tags from './Tags'

type VisitTypeCategoryItemsProps = {
  onLoadVisitType: (type: IVisitType) => void
  onSelectVisitType: (type: IVisitType) => Promise<void>
  onSelectVisitTypeCategory: (type: IVisitCategory) => void
  selectVisitType?: IVisitType | null
  visitCategory: IVisitCategory
  visitTypes: IVisitType[]
}

const VisitTypeCategoryItems: React.FunctionComponent<VisitTypeCategoryItemsProps> = ({
  onLoadVisitType,
  onSelectVisitType,
  onSelectVisitTypeCategory,
  selectVisitType = null,
  visitCategory,
  visitTypes,
}) => {
  const language = useBookingFormStore((state) => state.language)

  return (
    <TwoColumnsGrid>
      {visitTypes.map((visitType) => (
        <Card
          description={
            <>
              <Typography className="text-muted uppercase" component="span" variant="caption">
                <FormattedMessage
                  defaultMessage="{count, plural, one {min. # night} other {min. # nights}}"
                  description="Count night in Visit type category items"
                  values={{ count: visitType.nights_min }}
                />
              </Typography>
              <div className="my-4 h-3">
                {visitType.tags.length > 0 && (
                  <Tags
                    language={language}
                    select={selectVisitType?.id === visitType.id}
                    tags={visitType.tags}
                  />
                )}
              </div>
            </>
          }
          key={visitType.id}
          onDetail={() => onLoadVisitType(visitType)}
          onSelect={async () => {
            onSelectVisitTypeCategory(visitCategory)
            await onSelectVisitType(visitType)
          }}
          price={getPriceNumber(visitType.lowest_price, 'amount_per_night', language)}
          selected={selectVisitType?.id === visitType.id}
          title={getLang(visitType.name, language)}
        />
      ))}
    </TwoColumnsGrid>
  )
}

export default VisitTypeCategoryItems
