import clsx from 'classnames'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import SelectButton from '../SelectButton'
import GuestIcon from './GuestIcon'
import styles from './Guests.module.scss'
import GuestsIcon from './GuestsIcon'

type GuestProps = {
  onChange: (value: number | null) => Promise<void>
  value: number | null
}

const Guests: React.FunctionComponent<GuestProps> = ({ onChange, value }) => (
  <div className={styles.root}>
    <SelectButton
      className={styles.rootItem}
      heading={
        <FormattedMessage defaultMessage="1 Guest" description="One Guest in Guests component" />
      }
      icon={<GuestIcon />}
      onClick={async () => await onChange(1)}
      selected={value === 1}
    />
    <SelectButton
      className={clsx(styles.rootItem, styles.rootMargin)}
      heading={
        <FormattedMessage defaultMessage="2 Guests" description="Two Guest in Guests component" />
      }
      icon={<GuestsIcon />}
      onClick={async () => await onChange(2)}
      selected={value === 2}
    />
  </div>
)

export default Guests
