import { Tooltip } from '@mui/material'
import { ReactNode } from 'react'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

interface ReservationButtonTooltipWrapperProps {
  children: ReactNode
  showTooltip: boolean
}

export const ReservationButtonTooltipWrapper = ({
  children,
  showTooltip,
}: ReservationButtonTooltipWrapperProps) => {
  if (!showTooltip) {
    return <>{children}</>
  }

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <FormattedMessage
          defaultMessage="First, choose the parameters of your stay"
          description="Message for disabled reservation button tooltip"
        />
      }
    >
      <div>{children}</div>
    </Tooltip>
  )
}
