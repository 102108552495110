import * as React from 'react'
import { IHotel } from '../../store/data'
import AccordionTitle from '../AccordionTitle'
import Stars from '../Stars'

export type HotelNameProps = {
  hotel: IHotel
}

const HotelName: React.FunctionComponent<HotelNameProps> = ({ hotel }) => (
  <AccordionTitle suffix={<Stars className="text-theme-main" value={hotel?.stars || 0} />}>
    {hotel.name}
  </AccordionTitle>
)

export default HotelName
