import * as React from 'react'
import { getLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'
import Dialog from './Dialog'
import Markdown from './Markdown'

const RoomTypeDialog: React.FunctionComponent = () => {
  const language = useBookingFormStore((state) => state.language)
  const setRoomTypeDialogData = useBookingFormStore((state) => state.setRoomTypeDialogData)
  const roomTypeDialog = useBookingFormStore((state) => state.roomTypeDialog)

  const { data } = roomTypeDialog

  return (
    <Dialog
      onClose={() => setRoomTypeDialogData(null)}
      open={Boolean(data)}
      title={getLang(data?.category, language) || ''}
    >
      <Markdown source={getLang(data?.description, language) || ''} />
    </Dialog>
  )
}

export default RoomTypeDialog
