import * as React from 'react'
import { FormattedNumber } from 'react-intl'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'

type FormattedPriceProps = {
  value: number
}

const FormattedPrice: React.FunctionComponent<FormattedPriceProps> = ({ value }) => {
  const currency = useBookingFormStore((state) => state.currency)

  return (
    <FormattedNumber currency={currency} maximumFractionDigits={0} style="currency" value={value} />
  )
}

export default FormattedPrice
