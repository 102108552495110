import { differenceInDays } from 'date-fns'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { getLang } from '../helpers'
import { useBookingFormStore } from '../providers/BookingFormStoreProvider'

export const useBookingDetails = () => {
  const language = useBookingFormStore((state) => state.language)
  const guest = useBookingFormStore((state) => state.guest)
  const hotel = useBookingFormStore((state) => state.hotel)
  const visitType = useBookingFormStore((state) => state.visitType)
  const travelDate = useBookingFormStore((state) => state.travelDate)

  const bookingDetails: React.ReactNode[] = React.useMemo(() => {
    const data = []
    if (visitType) {
      data.push(getLang(visitType?.name, language))
    }

    if (hotel && hotel?.name) {
      data.push(hotel.name)
    }

    if (guest) {
      data.push(
        <FormattedMessage
          defaultMessage="{count, plural, one {#{whiteSpace}Guest} other {#{whiteSpace}Guests}}"
          description="Count guests in Booking details"
          values={{ count: parseInt(String(guest || 0)), whiteSpace: '\u00A0' }}
        />
      )
    }

    if (travelDate.from && travelDate.to) {
      data.push(
        <FormattedMessage
          defaultMessage="{count, plural, one {#{whiteSpace}night} other {#{whiteSpace}nights}}"
          description="Count nights in Booking details"
          values={{ count: differenceInDays(travelDate.to, travelDate.from), whiteSpace: '\u00A0' }}
        />
      )
    }

    return data
  }, [guest, visitType, hotel, language, travelDate])

  return { data: bookingDetails }
}
