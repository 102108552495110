import * as React from 'react'
import InfoIcon from '../InfoIcon'
import styles from './CardDetailLink.module.scss'

type HotelDetailLinkProps = {
  onClick: () => void
}

const CardDetailLink: React.FunctionComponent<HotelDetailLinkProps> = ({ onClick }) => (
  <div className={styles.root}>
    <a
      href="#"
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onClick()
      }}
      rel="noreferrer noopener"
      title="Detail hotel"
    >
      <InfoIcon />
    </a>
  </div>
)

export default CardDetailLink
