import clsx from 'classnames'
import * as React from 'react'
import BasicButton, { BasicButtonProps } from './BasicButton'
import styles from './SelectButton.module.scss'
import Typography from './Typography'

interface SelectButtonProps extends BasicButtonProps {
  center?: boolean
  heading: React.ReactNode
  icon: React.ReactNode
  row?: boolean
  selected?: boolean
  title?: string
}

const SelectButton: React.FunctionComponent<SelectButtonProps> = ({
  center = true,
  className = undefined,
  heading,
  icon,
  onClick,
  row = false,
  selected = false,
  title = undefined,
  ...rest
}) => (
  <BasicButton
    {...rest}
    className={clsx(
      styles.root,
      {
        [styles.rootCenter]: center,
        [styles.rootRow]: row,
        [styles.rootSelected]: selected,
      },
      className
    )}
    clearBasicStyle
    onClick={onClick}
    title={title}
  >
    <div className={styles.rootIcon}>{icon}</div>
    <Typography component="span" variant="subcategory">
      {heading}
    </Typography>
  </BasicButton>
)

export default SelectButton
